<template>
  <nav
    class="fixed top-0 h-14 z-50 w-full bg-white border-gray-200 dark:bg-bgLightDark no-print"
  >
    <!--in ltr change lg:pr-3 to lg:pl-3-->
    <div :class="'px-3 py-1 lg:px-5 ' + (isRtl ? ' lg:pr-3 ' : 'lg:pl-3')">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <button
            data-drawer-target="logo-sidebar"
            data-drawer-toggle="logo-sidebar"
            aria-controls="logo-sidebar"
            type="button"
            class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            @click="isSidebarOpen = !isSidebarOpen"
          >
            <span class="sr-only">Open sidebar</span>
            <!--if rtl add flip-horizontal to svg class-->
            <svg
              class="w-6 h-6 flip-horizontal"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                fill-rule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg>
          </button>
          <div class="flex flex-col">
            <!-- in ltr change(md:ml-24 to md:mr-24)-->
            <a
              href="/"
              :class="
                'flex mx-10  text-xl dark:text-textWhite hidden md:flex' +
                (isRtl ? ' md:ml-24 ' : 'md:mr-24')
              "
            >
              <img
                src="../../assets/logo.png"
                alt=""
                class="w-16 lg:w-24 mx-3"
              />
            </a>
          </div>
          <div class="dark:text-white mx-5">
            {{ $t("dolar_price") }} : {{ formatNumber(dolar_price) }}
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="mx-1"
            v-if="
              this.$store.getters['checkUserAccess']({
                id: '38',
                action: 'add',
              })
            "
          >
            <button
              type="button"
              class="flex relative text-sm rounded-full text-gray-600 dark:text-gray-200"
              id="darkModeToggle"
              @click="backup"
            >
              <span class="sr-only">DarkMode</span>
              <i class="fa-solid fa-download text-lg"></i>
            </button>
          </div>
          <div class="mx-2">
            <button
              @click="toggleTheme"
              type="button"
              class="flex relative text-sm rounded-full text-gray-600 dark:text-gray-200"
              id="darkModeToggle"
            >
              <span class="sr-only">DarkMode</span>
              <i class="fa-solid fa-moon text-lg" v-if="!isDark"></i>
              <i class="fa-solid fa-sun text-lg" v-if="isDark"></i>
            </button>
          </div>

          <!--user profile-->
          <div class="flex items-center mx-1">
            <div>
              <button
                type="button"
                class="flex text-sm rounded-full items-center space-x-1"
                aria-expanded="false"
                data-dropdown-toggle="dropdown-user"
              >
                <span
                  class="font-semibold text-gray-600 dark:text-textWhite1 px-1"
                >
                  <div
                    class="flex text-sm rounded-full items-center space-x-1"
                    v-if="currentUser != '' && currentUser != null"
                  >
                    <span
                      class="font-semibold text-gray-600 dark:text-textWhite1 px-1 text-sm"
                      >{{ currentUser.name }}</span
                    >
                  </div></span
                >
              </button>
            </div>
            <div class="">
              <button
                type="button"
                class="flex text-sm rounded-full items-center space-x-1"
                v-if="currentUser != '' && currentUser != null"
                @click="logout"
              >
                <span>
                  <i
                    class="fa fa-sign-out text-red-600 text-lg"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!--if ltr change (right-0 to left-0) (translate-x-full to -translate-x-full)-->
  <aside
    id="logo-sidebar"
    :class="
      'absolute top-3 z-40 w-60  pt-16 transition-transform right-0  bg-white dark:bg-bgLightDark md:translate-x-0 ' +
      (isSidebarOpen
        ? ''
        : isRtl
        ? ' translate-x-full hidden md:block '
        : ' -translate-x-full  hidden md:block ') +
      (isRtl ? ' right-0 ' : ' left-0 ')
    "
    aria-label="Sidebar"
  >
    <div class="h-full px-4 pt-3 pb-4 bg-white dark:bg-bgLightDark">
      <ul class="space-y-2 font-semibold">
        <li v-for="item in sidebarItems" :key="item.id">
          <button
            type="button"
            :class="
              'flex items-center w-full p-2 text-sm text-textBlack4 dark:text-textWhite2 transition duration-75 rounded-lg group  ' +
              (isActive(item)
                ? 'bg-primaryColor  text-textWhite hover:text-textWhite '
                : ' hover:text-primaryColor')
            "
            aria-controls="dropdown-sale"
            data-collapse-toggle="dropdown-sale"
            @click="toggleUl(item)"
            v-if="item.access"
          >
            <i :class="item.icon"></i>
            <!--in ltr change text-right to -->
            <span
              :class="
                'flex-1 mx-1  whitespace-nowrap flex justify-between ' +
                (isRtl ? ' text-right' : ' text-left')
              "
            >
              <span
                :class="
                  item.id == 'items' && expireCount > 0 ? ' text-red-500' : ''
                "
              >
                {{ item.name }}</span
              >
              <span
                v-if="item.id == 'items' && expireCount > 0"
                class="text-lg text-red-500 mx-3"
              >
                {{ expireCount }}</span
              >
              <a
                :href="item.tutorial"
                target="_blank"
                v-if="
                  item.subItems.length == 0 &&
                  $store.getters['checkTutorialAccess']
                "
              >
                <i
                  :class="
                    'fa-brands fa-youtube p-1 text-sm ' +
                    (isActive(item) ? 'text-textWhite' : 'text-primaryColor')
                  "
                ></i> </a
            ></span>
            <svg
              class="w-2.5 h-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
              v-show="item.subItems.length > 0"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          <ul
            id="dropdown-sale"
            class="py-2 space-y-2 mx-2"
            v-show="item.isOpen"
          >
            <li v-for="subItem in item.subItems" :key="subItem.id">
              <button
                @click="routeTo(subItem)"
                :class="
                  'text-sm flex text-right items-center justify-between w-full p-1 text-textBlack5 dark:text-textWhite3 transition duration-75 rounded-lg px-2 group hover:text-primaryColor ' +
                  (isActiveSubItem(subItem)
                    ? 'text-primaryColor  hover:text-textWhite'
                    : ' hover:text-primaryColor')
                "
                v-if="subItem.access"
              >
                <span
                  :class="
                    subItem.id == 'expire-item' && expireCount > 0
                      ? ' text-red-500'
                      : ''
                  "
                >
                  {{ subItem.text }}</span
                >

                <span
                  v-if="subItem.id == 'expire-item' && expireCount > 0"
                  class="text-lg text-red-500"
                >
                  {{ expireCount }}</span
                >
                <a
                  :href="subItem.tutorial"
                  target="_blank"
                  v-if="$store.getters['checkTutorialAccess'] && subItem.no_tutorial!=true"
                >
                  <i
                    class="fa-brands fa-youtube text-primaryColor p-1 text-sm"
                  ></i>
                </a>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </aside>
  <div class="h-5"></div>
  <transition name="fade">
    <div class="popup-modal overflow-y-auto" v-if="isSidebarOpen">
      <div class="window">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>
<script>
import { formatNumber } from "../../utils/functions";
import { SERVER_URL } from "../../constants/config";
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      isSidebarOpen: false,
      isDark: false,
      ulVisibility: {}, // Initialize with an empty object

      sidebarItems: [
        {
          id: "dashboard",
          name: this.$t("dashboard"),
          isOpen: false,
          icon: "fa-solid fa-gauge-high px-2 ",
          href: "/",
          subItems: [],
          tutorial: "https://youtu.be/MoJCnN1F3iE",
          access: this.$store.getters["checkUserAccess"]({
            id: "1",
            action: "view",
          }),
        },
        {
          id: "items-settings",
          name: this.$t("items_settings"),
          icon: "fa-solid fa-boxes-stacked px-2",
          href: "/",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "2",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "2",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "3",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "3",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "4",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "4",
              action: "add",
            }),
          subItems: [
            {
              id: "add-unit",
              text: this.$t("add_item_unit"),
              href: "/item-unit",
              tutorial: "https://youtu.be/Q1oloj-gWLM",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "2",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "2",
                  action: "view",
                }),
            },
            {
              id: "ca1",
              text: this.$t("item_categorys"),
              href: "/item-category",
              tutorial: "https://youtu.be/yOQpnJ5HN60",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "3",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "3",
                  action: "view",
                }),
            },
            {
              id: "caw1",
              text: this.$t("waste_items_categorys"),
              href: "/item-waste-category",
              tutorial: "https://youtu.be/iaLaeHFK3vA",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "4",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "4",
                  action: "view",
                }),
            },
          ],
        },
        {
          id: "froshtn",
          name: this.$t("sale_settings"),
          icon: "fa-solid fa-shop px-2",
          href: "/add-sale-type",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "5",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "5",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "6",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "6",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "7",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "7",
              action: "add",
            }),
          subItems: [
            {
              id: "add-type",
              text: this.$t("sale_types"),
              href: "/add-sale-type",
              tutorial: "https://youtu.be/zEY8Quk833w",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "5",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "5",
                  action: "view",
                }),
            },
            {
              id: "platform-froshtn",
              text: this.$t("sale_platforms"),
              href: "/sale-platform",
              tutorial: "https://youtu.be/ocN28yg0YtU",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "6",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "6",
                  action: "view",
                }),
            },
            {
              id: "platform-frosht1n",
              text: this.$t("sale_citys"),
              href: "/sale-city",
              tutorial: "https://youtu.be/M0Vlw7jNp4s",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "7",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "7",
                  action: "view",
                }),
            },
          ],
        },
        {
          id: "items",
          name: this.$t("items"),
          icon: "fa-solid fa-boxes-stacked px-2",
          href: "/",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "8",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "8",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "9",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "11",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "12",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "13",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "13",
              action: "view",
            }),
          subItems: [
            {
              id: "add-item",
              text: this.$t("add_new_item"),
              href: "/add-item",
              tutorial: "https://youtu.be/nH1YNokZzPM",
              access: this.$store.getters["checkUserAccess"]({
                id: "8",
                action: "add",
              }),
            },
            {
              id: "add-item-from-csv",
              text: this.$t("add_item_from_csv"),
              href: "/add-item-from-csv",
              tutorial: "https://youtu.be/DZ5WGu3hayI",
              access: this.$store.getters["checkUserAccess"]({
                id: "9",
                action: "add",
              }),
            },
            {
              id: "item-list",
              text: this.$t("items_list"),
              href: "/item-list",
              tutorial: "https://youtu.be/nH1YNokZzPM",
              access: this.$store.getters["checkUserAccess"]({
                id: "8",
                action: "view",
              }),
            },
            {
              id: "shortage-item",
              text: this.$t("shortage_items"),
              href: "/shortage-item",
              tutorial: "https://youtu.be/Sfv47TbWBmM",
              access: this.$store.getters["checkUserAccess"]({
                id: "11",
                action: "view",
              }),
            },
            {
              id: "expire-item",
              text: this.$t("expire_items"),
              href: "/expire-item",
              tutorial: "https://youtu.be/Sfv47TbWBmM",
              access: this.$store.getters["checkUserAccess"]({
                id: "12",
                action: "view",
              }),
            },
            {
              id: "waste-item",
              text: this.$t("waste_items"),
              href: "/waste-item",
              tutorial: "https://youtu.be/Pi9AKEjOzNI",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "13",
                  action: "view",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "13",
                  action: "add",
                }),
            },
          ],
        },
        {
          id: "box",
          name: this.$t("boxes"),
          icon: "fa-solid fa-box px-2",
          href: "/",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "14",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "14",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "15",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "15",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "16",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "16",
              action: "add",
            }),
          subItems: [
            {
              id: "add-box",
              text: this.$t("add_boxes"),
              href: "/all-box",
              tutorial: "https://youtu.be/FcCD2kKSWJQ",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "14",
                  action: "view",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "14",
                  action: "add",
                }),
            },
            {
              id: "exchange-box",
              text: this.$t("exchange_inside_boxes"),
              href: "/exchange-box",
              tutorial: "https://youtu.be/FdMIu48oBko",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "15",
                  action: "view",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "15",
                  action: "add",
                }),
            },
            {
              id: "transfer-box",
              text: this.$t("transfer_between_boxes"),
              href: "/transfer-box",
              tutorial: "https://youtu.be/u0cuaFq1RDs",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "16",
                  action: "view",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "16",
                  action: "add",
                }),
            },
          ],
        },
        {
          id: "purchase",
          name: this.$t("purchase"),
          icon: "fa-solid fa-shopping-cart px-2",
          href: "/new-purchase",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "17",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "17",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "18",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "18",
              action: "add",
            }),
          subItems: [
            {
              id: "new-purchase",
              text: this.$t("new_purchase"),
              href: "/new-purchase",
              tutorial: "https://youtu.be/oCCl38mhkw8",
              access: this.$store.getters["checkUserAccess"]({
                id: "17",
                action: "add",
              }),
            },
            {
              id: "purchase-list",
              text: this.$t("purchases_list"),
              href: "/purchase-list",
              tutorial: "https://youtu.be/tA-r8wwQ6EE",
              access: this.$store.getters["checkUserAccess"]({
                id: "17",
                action: "view",
              }),
            },
            {
              id: "new-return-purchase",
              text: this.$t("return_item_to_suplier"),
              href: "/new-return-purchase",
              tutorial: "https://youtu.be/TAvL_Ku-JHM",
              access: this.$store.getters["checkUserAccess"]({
                id: "18",
                action: "add",
              }),
            },
            {
              id: "return-purchases",
              text: this.$t("return_purchases"),
              href: "/return-purchases",
              tutorial: "https://youtu.be/TAvL_Ku-JHM",
              access: this.$store.getters["checkUserAccess"]({
                id: "18",
                action: "view",
              }),
            },
          ],
        },
        {
          id: "froshtn",
          name: this.$t("sale"),
          icon: "fa-solid fa-scale-balanced px-2",
          href: "/sale-pos",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "19",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "19",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "20",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "20",
              action: "add",
            }),
          subItems: [
            {
              id: "sale-pos",
              text: this.$t("new_sale"),
              href: "/sale-pos",
              tutorial: "https://youtu.be/1VYPsTE2iic",
              access: this.$store.getters["checkUserAccess"]({
                id: "19",
                action: "add",
              }),
            },
            {
              id: "sold-list",
              text: this.$t("solds"),
              href: "/sold-list",
              tutorial: "https://youtu.be/8ILOtGxFbqY",
              access: this.$store.getters["checkUserAccess"]({
                id: "19",
                action: "view",
              }),
            },
            {
              id: "return-invoice",
              text: this.$t("return_invoices"),
              href: "/return-invoice",
              tutorial: "https://youtu.be/h5LjeY68qHY",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "20",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "20",
                  action: "view",
                }),
            },
          ],
        },

        {
          id: "debt",
          name: this.$t("debts"),
          icon: "fa-solid fa-file-invoice-dollar px-2",
          href: "/",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "21",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "21",
              action: "view",
            }),
          subItems: [
            {
              id: "add-debt",
              text: this.$t("add_new_debt"),
              href: "/add-debt",
              tutorial: "https://youtu.be/93j405tBPPo",
              access: this.$store.getters["checkUserAccess"]({
                id: "21",
                action: "add",
              }),
            },
            {
              id: "debt-list",
              text: this.$t("debts_list"),
              href: "/debt-list",
              tutorial: "https://youtu.be/ySsvIaYRNtA",
              access: this.$store.getters["checkUserAccess"]({
                id: "21",
                action: "view",
              }),
            },
          ],
        },

        {
          id: "expense",
          name: this.$t("expenses"),
          icon: "fa-solid fa-sack-dollar px-2",
          href: "/",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "22",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "22",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "23",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "23",
              action: "view",
            }),
          subItems: [
            {
              id: "expense-category",
              text: this.$t("expenses_categorys"),
              href: "/expense-category",
              tutorial: "https://youtu.be/Wh1BELkQUNo",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "22",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "22",
                  action: "view",
                }),
            },
            {
              id: "add-expense",
              text: this.$t("add_new_expense"),
              href: "/add-expense",
              tutorial: "https://youtu.be/tdCyP_D5WmM",
              access: this.$store.getters["checkUserAccess"]({
                id: "23",
                action: "add",
              }),
            },
            {
              id: "expense-list",
              text: this.$t("expenses_list"),
              href: "/expense-list",
              tutorial: "https://youtu.be/2QyvV-MvUiE",
              access: this.$store.getters["checkUserAccess"]({
                id: "23",
                action: "view",
              }),
            },
          ],
        },
        {
          id: "income",
          name: this.$t("incomes"),
          icon: "fa-solid fa-hand-holding-dollar px-2",
          href: "/",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "24",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "24",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "25",
              action: "add",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "25",
              action: "view",
            }),
          subItems: [
            {
              id: "income-category",
              text: this.$t("incomes_categorys"),
              href: "/income-category",
              tutorial: "https://youtu.be/RIGhzJ7gPeU",
              access:
                this.$store.getters["checkUserAccess"]({
                  id: "24",
                  action: "add",
                }) ||
                this.$store.getters["checkUserAccess"]({
                  id: "24",
                  action: "view",
                }),
            },
            {
              id: "add-income",
              text: this.$t("add_new_income"),
              href: "/add-income",
              tutorial: "https://youtu.be/wdYdGovSSlU",
              access: this.$store.getters["checkUserAccess"]({
                id: "25",
                action: "add",
              }),
            },
            {
              id: "income-list",
              text: this.$t("incomes_list"),
              href: "/income-list",
              tutorial: "https://youtu.be/YOofhXfAuLA",
              access: this.$store.getters["checkUserAccess"]({
                id: "25",
                action: "view",
              }),
            },
          ],
        },

        {
          id: "report",
          name: this.$t("reports"),
          icon: "fa-solid fa-file-invoice-dollar px-2",
          href: "/",
          isOpen: false,
          access: this.$store.getters["checkUserAccess"]({
            id: "26",
            action: "view",
          }),
          subItems: [
            {
              id: "all-yearly-report",
              text: this.$t("annual_sales_report"),
              href: "/all-yearly-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              no_tutorial: true,
              access: true,
            },
            {
              id: "category-report",
              text: this.$t("category_sales_reports"),
              href: "/category-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "city-report",
              text: this.$t("city_sales_reports"),
              href: "/city-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "platform-report",
              text: this.$t("platform_sales_reports"),
              href: "/platform-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "sale-report",
              text: this.$t("sale_type_sales_reports"),
              href: "/sale-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "employee-report",
              text: this.$t("employee_sales_reports"),
              href: "/employee-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "item-report",
              text: this.$t("item_sales_reports"),
              href: "/item-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "customer-report",
              text: this.$t("customer_sales_reports"),
              href: "/customer-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "waste-category-report",
              text: this.$t("waste_category_reports"),
              href: "/waste-category-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "waste-item-report",
              text: this.$t("waste_item_reports"),
              href: "/waste-item-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "expense-category-report",
              text: this.$t("expense_category_reports"),
              href: "/expense-category-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "expense-customer-report",
              text: this.$t("expense_customer_reports"),
              href: "/expense-customer-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "income-customer-report",
              text: this.$t("income_customer_reports"),
              href: "/income-customer-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "income-category-report",
              text: this.$t("income_category_reports"),
              href: "/income-category-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "debt-report",
              text: this.$t("debt_reports"),
              href: "/debt-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "suplier-report",
              text: this.$t("suplier_purchase_reports"),
              href: "/suplier-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
            {
              id: "purchase-item-report",
              text: this.$t("item_purchase_reports"),
              href: "/purchase-item-report",
              tutorial: "https://www.youtube.com/watch?v=1QX6Z8QV9ZM",
              access: true,
              no_tutorial: true,
            },
          ],
        },

        {
          id: "role",
          name: this.$t("roles"),
          icon: "fa-solid fa-file-invoice-dollar px-2",
          href: "/role",
          isOpen: false,
          access:
            this.$store.getters["checkUserAccess"]({
              id: "27",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "27",
              action: "add",
            }),
          subItems: [
            {
              id: "add-role",
              text: this.$t("add_new_role"),
              href: "/add-role",
              tutorial: "https://youtu.be/zfJFWHZAFUs",
              access: this.$store.getters["checkUserAccess"]({
                id: "27",
                action: "add",
              }),
            },
            {
              id: "role-list",
              text: this.$t("roles_list"),
              href: "/role-list",
              tutorial: "https://youtu.be/zfJFWHZAFUs",
              access: this.$store.getters["checkUserAccess"]({
                id: "27",
                action: "view",
              }),
            },
          ],
        },
        {
          id: "employee",
          name: this.$t("employees"),
          icon: "fas fa-users px-2",
          href: "/employee",
          isOpen: false,
          tutorial: "https://youtu.be/fALcl72zmHs",

          subItems: [],
          access:
            this.$store.getters["checkUserAccess"]({
              id: "28",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "28",
              action: "add",
            }),
        },
        {
          id: "customers",
          name: this.$t("customers"),
          icon: "fa-solid fa-users px-2",
          href: "/customer",
          isOpen: false,
          tutorial: "https://youtu.be/F3AtTpnhq8k",
          subItems: [],
          access:
            this.$store.getters["checkUserAccess"]({
              id: "29",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "29",
              action: "add",
            }),
        },
        {
          id: "supliers",
          name: this.$t("supliers"),
          icon: "fa-solid fa-boxes-packing px-2",
          href: "/suplier",
          isOpen: false,
          tutorial: "https://youtu.be/-gn2lcG61xI",
          subItems: [],
          access:
            this.$store.getters["checkUserAccess"]({
              id: "30",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "30",
              action: "add",
            }),
        },
        {
          id: "dolar_price",
          name: this.$t("dolar_price"),
          icon: "fa-solid fa-hand-holding-dollar px-2",
          href: "/dolar-price",
          isOpen: false,
          tutorial: "https://youtu.be/RyQutn7C3RE",
          subItems: [],
          access:
            this.$store.getters["checkUserAccess"]({
              id: "31",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "31",
              action: "add",
            }),
        },
        {
          id: "note",
          name: this.$t("notes"),
          icon: "fa-solid fa-note-sticky px-2",
          href: "/note",
          isOpen: false,
          tutorial: "https://youtu.be/xlDBnoA8VPo",
          subItems: [],
          access:
            this.$store.getters["checkUserAccess"]({
              id: "32",
              action: "view",
            }) ||
            this.$store.getters["checkUserAccess"]({
              id: "32",
              action: "add",
            }),
        },
        {
          id: "barcode",
          name: this.$t("barcode"),
          icon: "fa-solid fa-barcode px-2",
          href: "/barcode",
          isOpen: false,
          tutorial: "https://youtu.be/QGzjb5TIyug",
          subItems: [],
          access: this.$store.getters["checkUserAccess"]({
            id: "33",
            action: "add",
          }),
        },
        {
          id: "change_password",
          name: this.$t("change_password"),
          icon: "fa-solid fa-unlock-keyhole px-2",
          href: "/change-password",
          isOpen: false,
          tutorial: "https://youtu.be/E2nAO0xgJTA",
          subItems: [],
          access: this.$store.getters["checkUserAccess"]({
            id: "34",
            action: "edit",
          }),
        },
        {
          id: "settings",
          name: this.$t("settings"),
          icon: "fa-solid fa-gear px-2",
          href: "/settings",
          isOpen: false,
          tutorial: "https://youtu.be/JuFZmYKLpCg",
          subItems: [],
          access: this.$store.getters["checkUserAccess"]({
            id: "35",
            action: "edit",
          }),
        },
        {
          id: "history-log",
          name: this.$t("activity_history"),
          icon: "fa-solid fa-history px-2",
          href: "/history-log",
          isOpen: false,
          tutorial: "https://youtu.be/uSYqVS-1uZU",
          subItems: [],
          access: this.$store.getters["checkUserAccess"]({
            id: "37",
            action: "view",
          }),
        },
        {
          id: "faq",
          name: this.$t("faq"),
          icon: "fa-solid fa-question px-2",
          href: "/faq",
          isOpen: false,
          tutorial: "https://youtu.be/DpMa4-F7Pbc",
          subItems: [],
          access: this.$store.getters["checkUserAccess"]({
            id: "36",
            action: "edit",
          }),
        },
        // Add more sidebar items as needed
      ],
      expireCount: 0,
    };
  },
  computed: {
    dolar_price() {
      return this.$store.state.navbar.dp_price;
    },
    currentUser() {
      return this.$store.state.navbar.currentUser;
    },
    isRtl() {
      return (
        localStorage.getItem("lang_wise_manager") == "ar" ||
        localStorage.getItem("lang_wise_manager") == "ku" ||
        //if null
        localStorage.getItem("lang_wise_manager") == null ||
        //if empty
        localStorage.getItem("lang_wise_manager") == ""
      );
    },
    currentSetting() {
      return this.$store.state.settings.settings;
    },
  },
  created() {
    this.isDark = localStorage.getItem("theme") == "dark";
    this.getData();
    this.getSettings();
  },

  methods: {
    async getSettings() {
      await this.$store.dispatch("settings/getSettings", {});

      localStorage.setItem(
        "currentSettingWiseManager",
        JSON.stringify(this.currentSetting)
      );
      var isHasAccessExpire = this.$store.getters["checkUserAccess"]({
        id: "12",
        action: "view",
      });
      if (isHasAccessExpire) {
        var res = await this.$store.dispatch("navbar/getExpiredCount", {});
        this.expireCount = res.data;
      }
    },
    formatNumber(number) {
      return formatNumber(number);
    },
    logout() {
      //clear the token
      localStorage.removeItem("token_wise_manager");
      localStorage.removeItem("user_wise_manager");
      window.location.href = "/login";
    },
    getData() {
      this.$store.dispatch("navbar/getItems");
      this.$store.dispatch("navbar/getCurrentUser");
    },
    /* backup() {
      this.$store.dispatch("navbar/backup");
    },*/
    backup() {
      const token = localStorage.getItem("token_wise_manager");
      const url = SERVER_URL + `backup?token=${token}`;

      window.open(url, "_blank");
      // Alternatively, use the fetch API:
      // fetch(url, { headers: { 'Authorization': 'Bearer ' + token } });
    },
    toggleUl(item) {
      if (item.subItems.length > 0) {
        item.isOpen = !item.isOpen;
      }
      if (item.subItems.length == 0) {
        this.$router.push(item.href);
      }
    },
    routeTo(item) {
      this.$router.push(item.href);
    },
    isActive(item) {
      if (item.subItems.length == 0) {
        return this.$route.path == item.href;
      }
      //if any subItem is active return true
      return item.subItems.some((subItem) => {
        if (this.$route.path == subItem.href) {
          item.isOpen = true;
        }

        return this.$route.path == subItem.href;
      });
    },
    isActiveSubItem(item) {
      return this.$route.path == item.href;
    },
    toggleTheme() {
      this.isDark = !this.isDark;
      localStorage.setItem("theme", this.isDark ? "dark" : "light");

      //reload the page
      window.location.reload();
    },
  },
};
</script>
<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 5;
}

.window {
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
