//export const SERVER_URL = "http://127.0.0.1:8000/api/";
//export const FILE_URL = "http://127.0.0.1:8000/storage/";
export const SERVER_URL = "https://shamamk-api.wise-manager.co/api/";
export const FILE_URL = "https://shamamk-api.wise-manager.co/WiseManager/public/storage/";

//export a list of constants
export const CURRENCYS = [
  {
    cur_id: 1,
    id: 1,
    cur_name: "dinar",
    cur_symbol: "IQD",
    cur_is_main: 1,
  },
  {
    cur_id: 2,
    id: 1,
    cur_name: "dollar",
    cur_symbol: "$",
    cur_is_main: 0,
  },
];
